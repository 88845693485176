import React from "react";
import Social from "./Social";

const Home = () => {
  return (
    <>
      <div className="ews_tm_home">
        <div className="home_content">
          <div className="avatar">
            <div
              className="image avatar_img"
            >
              <img src="/assets/img/home/1.jpg" alt="brand" />
            </div>
            {/* END AVATAR IMAGE */}
          </div>
          {/* END AVATAR */}
          <div className="details">
            <h3 className="name">Amy Bower</h3>
            <p className="job">
              Creative Photographer based in New York and happy to travel all
              over Europe to capture photos.
            </p>
            {/* END JOB */}
            <Social />
          </div>
          {/* END DETAILS */}
        </div>
        {/* END HOME CONTENT */}
      </div>
      {/* END HOME */}
    </>
  );
};

export default Home;
