import React,{useState} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import Home from "../../components/Home";
import About from "../../components/About";
import Portfolio from "../../components/Portfolio";
import News from "../../components/News";
import Contact from "../../components/Contact";

const HomeLight = () => {
  const [lightMode, setLightMode] = useState(true);


  const toogleThemeMode = () =>{
    setLightMode(!lightMode);
    if(lightMode){
      document.body.classList.add("dark");

    }else{
      document.body.classList.remove("dark");
    }
  }

  return (
    <>
      <Tabs>
        <TabList>
          {/* START LEFT MENU CONTENT */}
          <div className="leftpart">
            <div className="leftpart_inner">
              <div className="logo">
                <Link className="navbar-brand" to="/">
                  <img src={document.body.classList['0'] === 'color' ? `/assets/img/logo/logo.png`: `/assets/img/logo/dark.png`} alt="brand" />
                </Link>
              </div>
              {/* END LOGO */}
<span className="line"/>
 {/* END line */}
              <div className="menu">
                <ul>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/home-run.svg"
                      alt="homerun"
                    />
                    <span className="menu_content">Home</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/avatar.svg"
                      alt="avatar"
                    />
                    <span className="menu_content">About</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/briefcase.svg"
                      alt="briefcase"
                    />
                    <span className="menu_content">Portfolio</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/paper.svg"
                      alt="paper"
                    />
                    <span className="menu_content">Blog</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/mail.svg"
                      alt="mail"
                    />
                    <span className="menu_content"> Contact</span>
                  </Tab>
             
                  <li><button className={!lightMode ? 'mode darken' : 'mode'} onClick={toogleThemeMode}>

<svg className="mainsvg " x="0px" y="0px" width="70px" height="70px" viewBox="0 0 70 70" >
        <defs>
        </defs>
        <path className="modeb sunb" d="M59.8,24.8V10.3H45.3L35,0L24.8,10.3H10.3v14.5L0,35l10.3,10.3v14.5h14.5L35,70l10.3-10.3h14.5V45.3L70,35L59.8,24.8z
	 M35,52.7c-9.8,0-17.7-7.9-17.7-17.7c0-9.8,7.9-17.7,17.7-17.7S52.7,25.2,52.7,35C52.7,44.8,44.8,52.7,35,52.7z" />
        <circle className="modeb lights" cx={35} cy={35} r="14.4" />
      </svg>

</button></li>
             
                </ul>
              </div>
              {/* END MENU */}
              <span className="line"/>
 {/* END line */}
              <div className="copyright">
                <p>
                  &copy; {new Date().getFullYear()} EWS <br /> Created by
                  <a
                    href="https://themeforest.net/user/explosionwebsolution"
                    target="_blank"
                    rel="noreferrer"
                  >
                    EWS-Themes
                  </a>
                </p>
              </div>
              {/* END COPYRIGHT */}
            </div>
          </div>
          {/* END LEFT MENU CONTENT */}
        </TabList>
        {/* END SIDEBAR TABLIST */}

        {/* START RIGHT PART CONTENT */}
        <div className="rightpart">
          <div className="rightpart_in">
            <div className="ews_tm_section">
              <div className="container">
                <TabPanel>
                  <Home />
                </TabPanel>
                {/* END HOME MENU TAB CONTENT */}

                <TabPanel>
                  <About />
                </TabPanel>
                {/* END ABOUT MENU TAB CONTENT */}

                <TabPanel>
                  <Portfolio />
                </TabPanel>
                {/* END PORTFOLIO MENU TAB CONTENT */}

                <TabPanel>
                  <News />
                </TabPanel>
                {/* END NEWS MENU TAB CONTENT */}

                <TabPanel>
                  <Contact />
                </TabPanel>
                {/* END CONTACT MENU TAB CONTENT */}
              </div>
            </div>
          </div>
        </div>
        {/* END RIGHT PART CONTENT */}
      </Tabs>
      {/* END TABS */}
    </>
  );
};

export default HomeLight;
